import React, { useState, useEffect }  from 'react'
import './schedule.css';

const Line = () => (
    <>
    <div className='line_container'>
        <div className='line' />
        <div className='line_start-outer'/>
        <div className='line_start-inner' />
    </div>
    </>
)

const OneSideContent = () => (
    <div>
        <div className='schedule_item-container'>
            <Line />
            <div className='schedule_item-container-right'>
                <p className='schedule_item-container-title'>Okupljanje</p>
                <p className='schedule_item-container-time'>Catering Kvatrić na Ravnicama u 14:00h</p>
                <p className='schedule_item-container-where'>Maksimirska Cesta 125b, 10000, Zagreb</p>
            </div>
        </div>
        <div className='schedule_item-container'>
            <Line />
            <div className='schedule_item-container-right'>
                <p className='schedule_item-container-title'>Vjenčanje</p>
                <p className='schedule_item-container-time'>Crkva Kraljice Svete Krunice u 17:30h</p>
                <p className='schedule_item-container-where'>Ulica Franje Župana 2, 10000, Zagreb</p>
            </div>
        </div>
        <div className='schedule_item-container'>
            <Line />
            <div className='schedule_item-container-right'>
                <p className='schedule_item-container-title'>Proslava</p>
                <p className='schedule_item-container-time'>Klub Šumica u 19:00h</p>
                <p className='schedule_item-container-where'>Brestovečka cesta 181, 10360, Sesvete</p>
            </div>
        </div>
    </div>
)

const TwoSideContent = () => (
    <div className='full_width_container_container'>
        <div className='schedule_item-container'>
            <div className='schedule_item-container-left' />
            <Line />
            <div className='schedule_item-container-right'>
                <p className='schedule_item-container-title'>Okupljanje</p>
                <p className='schedule_item-container-time'>Catering Kvatrić na Ravnicama u 14:00h</p>
                <p className='schedule_item-container-where'>Maksimirska Cesta 125b, 10000, Zagreb</p>
            </div>
        </div>
        <div className='schedule_item-container'>
            <div className='schedule_item-container-left'>
                <p className='schedule_item-container-left-title'>Vjenčanje</p>
                <p className='schedule_item-container-left-time'>Crkva Kraljice Svete Krunice u 17:30h</p>
                <p className='schedule_item-container-left-where'>Ulica Franje Župana 2, 10000, Zagreb</p>
            </div>
            <Line />
            <div className='schedule_item-container-right' />
        </div>
        <div className='schedule_item-container'>
            <div className='schedule_item-container-left' />
            <Line />
            <div className='schedule_item-container-right'>
                <p className='schedule_item-container-title'>Proslava</p>
                <p className='schedule_item-container-time'>Klub Šumica u 19:00h</p>
                <p className='schedule_item-container-where'>Brestovečka cesta 181, 10360, Sesvete</p>
            </div>
        </div>
    </div>
);

const Schedule = () => {
    const [showTwoSide, setShowElement] = useState(false);

    useEffect(() => {
        const handleResize = () => {
            const width = window.innerWidth;
            setShowElement(width > 700);
        };

        handleResize(); // Initial check
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <div id='schedule' className='schedule_container'>
            <div className='schedule_container-title'>
                <p>Kada i gdje</p>
            </div>

            {showTwoSide ? <TwoSideContent /> : <OneSideContent />}
        </div>
    )
}

export default Schedule
