import React from 'react'
import './header.css';
import cover_portrait from '../../assets/cover_portrait.jpg'
import cover_v3 from '../../assets/cover_v3.svg'

const Header = () => {
  return (
    <div className='wedding_header section_padding' id='home'>
      <div className='wedding_heading-content'>
        <img className='wedding_cover-image' src={cover_v3} alt='Cover'></img>
        <img className='wedding_cover-image_portrait' src={cover_portrait} alt='Cover'></img>
      </div>
    </div>
  )
}

export default Header
