import React from 'react'
import './intro.css';

const HorizontalLine = () => (
    <>
    <div className='horizontal_line' />
    </>
)

const Intro = () => {
  return (
    <div className='intro_container'>
        <div className='intro_message'>
            <p>Dođite zbog ljubavi,</p>
            <p>ostanite zbog zabave</p>
        </div>

        <div className='intro_date-container'>
            <div className='intro_date'>
                <HorizontalLine />
                <p>Subota</p>
                <HorizontalLine />
            </div>

            <div className='intro_full-date'>
                <p>   5 | Listopad | 2024</p>
            </div>
        </div>

        <div className='intro_footer-message'>
            <p>Prave ljubavi nemaju završetak,</p>
            <p>budite dio početka naše priče.</p>
        </div>
    </div>
  )
}

export default Intro
