import React, { useState } from 'react'
import { Navbar, Header, Rsvp, Schedule, Intro, Timer, Story, Loader, Popup } from './components'
import './App.css';

const App = () => {
  const [loaderState, setLoaderState] = useState('');
  const [attending, setAttending] = useState();

  const loaderStatusHandler = (status, isAttending) => {
    setLoaderState(status);
    setAttending(isAttending)
  };

  const closeHandler = () => {
    setLoaderState('');
  };

  return (
    <div className='App'>
      <div>
        <Navbar />
        <Header />
        <Intro />
        <Story />
        <Schedule />
        <Timer />
        <Rsvp loaderStatusHandler={loaderStatusHandler}/>
        <Overlay loaderState={loaderState} closeHandler={closeHandler} attending={attending}/>
      </div>
    </div>
  )
}

const Overlay = ({ loaderState, closeHandler, attending }) => {
  if (loaderState === 'loading') {
    return <Loader />;
  } else if (loaderState === 'success') {
    return <Popup closeHandler={closeHandler} title={attending === 'yes' ? 'Vidimo se uskoro!' : 'Žao nam je što ne možeš doći'} subtitle='Ako dođe do nekih promjena molim vas da nas obavijestite!' />;
  } else if (loaderState === 'error') {
    return <Popup closeHandler={closeHandler} title='Nešto je pošlo po krivu 😞' subtitle='Molimo te da provjeriš podatke i pokušaš ponovno' />;
  } else {
    return <div />;
  }
};
export default App
