import React from 'react'
import './story.css';
import storyCover_v2 from '../../assets/storyCover_v2.png'

const Story = () => {
  return (
    <div className='story_container'>
        <div className='story_item-container'>
            <div className='story_item-container-left'>
                <img className='story_cover-image' src={storyCover_v2} alt='Sotry cover'></img>
            </div>

            <div className='story_item-container-right'>
                <div className='story_item-container-title'>
                    <p>Nakon 8 godina zajedno</p>
                    <p>Odlučili smo se uozbiljiti</p>
                </div>
                <div className='story_item-container-subtitle'>
                    <p>
                        Naša avantura započela je u školskim klupama davne 2016. godine. Obilježilo ju je puno promjena, od školskih klupa, fakulteta i prvih poslova sve do zajedničkog života. Svaka faza nosila je svoje izazove, ali kroz smijeh, zajedničko učenje i podršku, brzo smo shvatili da među nama postoji posebna veza. Godine su prolazile, a naša ljubav je rasla. S velikim uzbuđenjem i ljubavlju, sada se pripremamo za naš veliki dan te jedva čekamo započeti novo poglavlje našeg zajedničkog života kao muž i žena.
                    </p>
                </div>
            </div>

            <div className='story_item-container-full'>
                <img className='story_cover-image' src={storyCover_v2} alt='Sotry cover'></img>
            </div>
        </div>
    </div>
  )
}

export default Story
