import React, { useState } from 'react';
import { db } from '../../services/firebaseApp.js';
import { doc, setDoc } from "firebase/firestore";
import './rsvp.css';

const Rsvp = ({ loaderStatusHandler }) => {
    const [fullName, setFullName] = useState('');
    const [email, setEmail] = useState('');
    const [plusOneName, setPlusOneName] = useState('');
    const [areYouComming, setAreYouComming] = useState('');
    const [bringingSomebody, setBringingSomebody] = useState('');
    const [musicRequest, setMusicRequest] = useState('');
    const [forgotSomething, setForgotSomething] = useState('');
  
    const handleInputChange = (e) => {
      const { id, value } = e.target;
      if (id === 'fullName') {
        setFullName(value);
      } else if (id === 'email') {
        setEmail(value);
      } else if (id === 'plusOneName') {
        setPlusOneName(value);
      } else if (id === 'musicRequest') {
        setMusicRequest(value);
      } else if (id === 'forgotSomething') {
        setForgotSomething(value);
      }
    };

    const handleAreYouComming = (event) => {
        const { value } = event.target;
        setAreYouComming(value)

        if (value === 'no') {
            setPlusOneName('');
            setMusicRequest('');
            setForgotSomething('');
            setBringingSomebody('');
        }
    };

    const handleAreYouBringingSomeone = (event) => {
        const { value } = event.target;
        setBringingSomebody(value)
    };

    const handleSubmitForm = async (event) => {
        if (fullName.trim().length === 0 || email.trim().length === 0 || areYouComming.trim().length === 0) {
            alert("Molim vas unesite email, ime i prezime te potvrdite svoj dolazak.")
            return
        }
        
        loaderStatusHandler('loading', areYouComming);

        try {
            await setDoc(doc(db, "rsvp", email.toLowerCase()), {
                email: email,
                forgotSomething: forgotSomething,
                fullName: fullName,
                musicRequest: musicRequest,
                plusOneName: plusOneName,
                rsvp: areYouComming
            });

            loaderStatusHandler('success', areYouComming);
        } catch (error) {
            console.log("Error " + error);
            loaderStatusHandler('error', areYouComming);
        }
    };
  
    return (
        <div id='rsvp' className='rsvp_container'> 
            <div className='rsvp_container-inner'>
                <div className='rsvp_title-container'>
                    <div className='rsvp_title'>
                        <p>Potvrdi svoj dolazak</p>
                    </div>
                </div>

                <div className='rsvp_question'>
                    <p className='rsvp-question-title'>Email:</p>
                    <input id='email' className='inputField' value={email} onChange={handleInputChange} />
                </div>

                <div className='rsvp_question'>
                    <p className='rsvp_question-title'>Ime i prezime:</p>
                    <input id='fullName' className='inputField' value={fullName} onChange={handleInputChange} />
                </div>

                <div className='rsvp_question'>
                    <p className='rsv_question-title'>Potvrdi dolazak:</p>
                    <div className='rsvp_question-inline'>   
                        <button id='yes' value='yes' className={'rsvp_question-options-button' + (areYouComming === 'yes' ? '-selected' : '')} onClick={handleAreYouComming}>Dolazim</button>
                        <button id='no' value='no' className={'rsvp_question-options-button' + (areYouComming === 'no' ? '-selected' : '')} onClick={handleAreYouComming}>Ne mogu doći</button>
                    </div>
                </div>

                {areYouComming === 'yes' && (
                    <div>
                        <div className='rsvp_question'>
                            <p className='rsvp_question-title'>Vodiš pratnju?</p>
                            <div className='rsvp_question-inline'>
                                <button id='yes' value='yes' className={'rsvp_question-options-button' + (bringingSomebody === 'yes' ? '-selected' : '')} onClick={handleAreYouBringingSomeone}>Da</button>
                                <button id='no' value='no' className={'rsvp_question-options-button' + (bringingSomebody === 'no' ? '-selected' : '')} onClick={handleAreYouBringingSomeone}>Ne</button>
                            </div>
                        </div>

                        {bringingSomebody === 'yes' && (
                            <div className='rsvp_question'>
                                <p className='rsvp_question-title'>Ime i prezime pratnje:</p>
                                <input id='plusOneName'className='inputField' value={plusOneName} onChange={handleInputChange} />
                            </div>
                        )}

                        <div className='rsvp_question'>
                            <p className='rsvp_question-title'>Muzička želja:</p>
                            <input id='musicRequest' className='inputField' value={musicRequest} onChange={handleInputChange} />
                        </div>

                        <div className='rsvp_question'>
                            <p className='rsvp_question-title'>Jesmo li nešto zaboravili?</p>
                            <input id='forgotSomething' className='inputField' value={forgotSomething} onChange={handleInputChange} />
                        </div>
                    </div>
                )}
                

                <div className='rsvp_submitButton-container'>
                    <button className='submitButton' onClick={handleSubmitForm}>Potvrdi</button>
                </div>

            </div>
        </div>
    );
}

export default Rsvp
