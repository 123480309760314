import React from 'react'
import './navbar.css';
import logo_full from '../../assets/logo_full.svg';
import logo from '../../assets/logo.svg';

const Menu = () => (
  <>
  <p><a href="#schedule">Kada i gdje</a></p>
  <p><a href="#rsvp">Potvrdi svoj dolazak</a></p>
  {/* <p><a href="#gallery">Gallery</a></p> */}
  </>
)

const Navbar = () => {
  return (
    <div className='wedding_navbar'>
      <div className='wedding_navbar-links'>
        <div>
          <div className='wedding_navbar-links_logo_full'>
            <a href='#home'><img src={logo_full} alt='Paula and Borna Full Logo'></img></a>
          </div>
          <div className='wedding_navbar-links_logo'>
            <a href='#home'><img src={logo} alt='Paula and Borna Logo'></img></a>
          </div>
        </div>
        
        <div className='wedding_navbar-links_container'>
          <Menu />
        </div>
        <div className='wedding_navbar-links-compact_container'>
          <p><a href="#rsvp">Potvrdi svoj dolazak</a></p>
        </div>
      </div>
    </div>
  )
}

export default Navbar
