import React from 'react'
import './statusComponents.css';
import Lottie from "lottie-react";
import loading from '../../assets/loading_v2.json';
import closeIcon from '../../assets/closeIcon.svg';

export const Loader = () => {
  return (
    <div className='loader_container'>
        <div className='loader_container-lottie'>
            <Lottie animationData={loading} loop={true} />
        </div>
    </div>
  )
};

export const Popup = ({ closeHandler, title, subtitle }) => {
  return (
    <div className='loader_container'>
      <div className='success_card'>
          <button className='close_button' onClick={closeHandler}>
            <img src={closeIcon} alt="Close icon" />
          </button>
          <p className='success_card-title'>{title}</p>
          <p className='success_card-message'>{subtitle}</p>
      </div>
    </div>
  )
};